<template>
  <div class="common-bg wrap hidden">
    <van-nav-bar :fixed="true" :title="$t('page.certification')" left-arrow placeholder @click-left="onClickLeft" />
    <div class="top18">
      <div class="padding0-12">
        <div class="padding24-20 radius10">
          <div class="flex between">
            <div class="btn_wrap flex flex-center common-bg round font30 type_item" v-for="(item, index) in typeArr"
              :key="index" :style="{
      backgroundColor: type === item.value ? 'var(--newBtn)' : '',
      color: type === item.value ? 'var(--btnsizeColor)' : 'var(--theme)',
    }" @click="typeChange(item)">
              {{ item.title }}
            </div>
          </div>
          <div class="top70 font24 sizeColor">{{ $t("Certification.name") }}</div>
          <div class="top16">
            <Input v-model="formData.realname" :disabled="status !== 0"></Input>
          </div>
          <div class="top36 font24 sizeColor">{{ typeObj[type] }}</div>
          <div class="top16">
            <Input v-model="formData.cardid" :disabled="status !== 0"></Input>
          </div>
          <div class="top36 font24 sizeColor">{{ $t("Certification.upload") }}</div>
          <div class="top16 flex upload">
            <div>
              <van-uploader v-model="card_front" :after-read="card_frontUpload" :show-upload="card_front.length === 0"
                :deletable="status === 0" @delete="deleteImage('card_front')" :style="{
      width: 'calc(220rem / 32)',
      height: 'calc(220rem / 32)',
    }" />
            </div>
            <div class="left30">
              <!-- 第二个上传框的 -->
              <van-uploader v-model="card_back" :deletable="status === 0" :after-read="card_backUpload"
                :show-upload="card_back.length === 0" @delete="deleteImage('card_back')" :style="{
      width: 'calc(220rem / 32)',
      height: 'calc(220rem / 32)',
    }" />
            </div>
          </div>
          <!-- 提交按钮 -->
          <div class="top80">
            <div class="radius10 flex flex-center font36 height80 btnSizeColor btnColor" @click="submit"
              :style="{ backgroundColor: status === 2 ? '#5ac725' : status === 1 ? '#f9ae3d' : '' }">
              {{ status == 0 ? $t("Certification.submit") : status == 1 ? $t("Certification.review") :
      $t("Certification.verified") }}
            </div>
          </div>
          <div class="top80"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "./components/login-input.vue";
import { NavBar, Uploader } from "vant";
import { $upload, $post, domain } from "@/utils/request.js";
export default {
  components: {
    Input,
    [NavBar.name]: NavBar,
    [Uploader.name]: Uploader,
  },
  data() {
    this.typeArr = [
      { title: this.$t("Certification.type.id"), value: "card" },
      { title: this.$t("Certification.type.driver"), value: "driver" },
      { title: this.$t("Certification.type.passport"), value: "passport" },
    ];
    this.typeObj = {
      card: this.$t("Certification.type.id"),
      driver: this.$t("Certification.type.driver"),
      passport: this.$t("Certification.type.passport"),
    };
    return {
      type: "card",
      formData: {
        level: 2,
      },
      card_front: [],
      card_back: [],
      card: {},
      driver: {},
      passport: {},
      status: 0
    };
  },
  mounted() { },
  async created() {
    this.getData();
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    typeChange(item) {
      if (this.type != item.value) {
        this.type = item.value;
        this.initFormData();
      }
    },
    async submit() {
      if (this.status !== 0) return;
      this.formData.auth_type = this.type;
      if (
        this.formData.realname &&
        this.formData.cardid &&
        this.formData.card_front &&
        this.formData.card_back
      ) {
        try {
          const res = await $post("/users/levelauth", this.formData);
          const { ret, msg, data } = res.data;
          if (ret == 1) {
            this.$toast(this.$t("Certification.submitSuccess"));
            setTimeout(() => {
              this.$router.go(-1);
            }, 800);
            return;
          }
          this.$toast(msg);
        } catch (error) { }
      } else {
        this.$toast(this.$t("Certification.incompleteInformation"));
      }
    },
    // 压缩前将file转换成img对象
    readImg(file) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        const reader = new FileReader()
        reader.onload = function (e) {
          img.src = e.target.result
        }
        reader.onerror = function (e) {
          reject(e)
        }
        reader.readAsDataURL(file)
        img.onload = function () {
          resolve(img)
        }
        img.onerror = function (e) {
          reject(e)
        }
      })
    },
    // 压缩图片
    async compressImg(file) {
      const img = await this.readImg(file);
      const type = img.type;
      return new Promise((resolve, reject) => {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        const { width: originWidth, height: originHeight } = img
        // 最大尺寸限制
        const maxWidth = 1000
        const maxHeight = 1000
        // 目标尺寸
        let targetWidth = originWidth
        let targetHeight = originHeight
        if (originWidth > maxWidth || originHeight > maxHeight) {
          if (originWidth / originHeight > 1) {
            //  宽图片
            targetWidth = maxWidth
            targetHeight = Math.round(maxWidth * (originHeight / originWidth))
          } else {
            // 高图片
            targetHeight = maxHeight
            targetWidth = Math.round(maxHeight * (originWidth / originHeight))
          }
        }
        canvas.width = targetWidth
        canvas.height = targetHeight
        context.clearRect(0, 0, targetWidth, targetHeight)
        // 图片绘制
        context.drawImage(img, 0, 0, targetWidth, targetHeight)
        canvas.toBlob(function (blob) {
          resolve(blob)
        }, type || 'image/png')
      })
    },
    // 第一个上传的事件
    async card_frontUpload(event) {
      event.status = 'uploading';
      event.message = this.$t("upload.uploading");
      const formdata = new FormData();
      try {
        const ImgData = await this.compressImg(event.file);
        const fileData = new File([ImgData], event.file.name, { type: ImgData.type })
        formdata.append("image", fileData);
      } catch (error) {
        formdata.append("image", event.file);
      }
      $upload({ url: "/users/upload", data: formdata })
        .then((res) => {
          if (res.ret === 1) {
            event.status = 'success';
            event.message = '';
            this.formData.card_front = res.data.path;
          } else {
            event.status = 'failed';
            event.message = this.$t("upload.failed");
            // this.$toast(this.$t("uploadErr"));
          }
        })
        .catch((err) => {
          event.status = 'failed';
          event.message = this.$t("upload.failed");
          // this.$toast(this.$t("uploadErr"));
        });
    },
    // 第二个上传的事件
    async card_backUpload(event) {
      event.status = 'uploading';
      event.message = this.$t("upload.uploading");
      const formdata = new FormData();
      try {
        const fileData = new File([ImgData], event.file.name, { type: ImgData.type })
        const ImgData = await this.compressImg(event.file);
        formdata.append("image", fileData);
      } catch (error) {
        formdata.append("image", event.file);
      }
      $upload({ url: "/users/upload", data: formdata })
        .then((res) => {
          if (res.ret === 1) {
            event.status = 'success';
            event.message = '';
            // this.card_back.push({ url: `${domain}${res.data.path}` });
            this.formData.card_back = res.data.path;
          } else {
            event.status = 'failed';
            event.message = this.$t("upload.failed");
            // this.$toast(this.$t("uploadErr"));
          }
        })
        .catch((err) => {
          event.status = 'failed';
          event.message = this.$t("upload.failed");
          // this.$toast(this.$t("uploadErr"));
        });
    },
    deleteImage(which) {
      (this[which] = []), (this.formData[which] = "");
    },
    async getData() {
      try {
        const res = await $post("/users/levelauth", {});
        const { ret, msg, data } = res.data;
        if (ret == 1) {
          if (data.auth_data && Object.keys(data.auth_data).length) {
            this[data.auth_data.auth_type] = data.auth_data;
            this.type = data.auth_data.auth_type;
            this.getStatus(data)
          }
          this.initFormData();
          return;
        }
        this.$toast(msg);
      } catch (error) { }
    },
    initFormData() {
      this.card_front = [];
      this.card_back = [];
      this.formData = this[this.type];
      this.formData.level = 2;
      if (this.formData.card_front) {
        this.card_front.push({ url: domain + this.formData.card_front });
      }
      if (this.formData.card_back) {
        this.card_back.push({ url: domain + this.formData.card_back });
      }
    },
    getStatus(data) {
      if (data.auth_level == 2) {
        this.status = 2
      } else if (data.auth_check != 0) {
        this.status = 1
      } else {
        this.status = 0
      }
    }
  },
};
</script>

<style scoped>
@import url("./common.css");

.btn_wrap {
  min-width: calc(160rem / 32);
  padding: 0 10px;
  height: calc(70rem / 32);
}

.type_item {
  /* white-space: nowrap; */
  text-align: center;
  /* word-break: keep-all; */
}

.upload>>>.u-upload__button {
  background-color: var(--bg);
  border-radius: calc(10rem / 32);
}

.upload_wrap {
  width: calc(220rem / 32);
  height: calc(220rem / 32);
}

.delete_wrap {
  border-radius: 50%;
  width: calc(30rem / 32);
  height: calc(30rem / 32);
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  right: -calc(10rem / 32);
  top: -calc(10rem / 32);
}

.file_wrap {
  width: calc(220rem / 32);
  height: calc(220rem / 32);
  position: absolute;
  top: 0;
  left: 0;
}

.sizeColor {
  color: var(--sizeColor);
}

.themeColor {
  color: var(--theme);
}

.bgColor {
  background-color: var(--nav-background);
}

.btnSizeColor {
  color: var(--newbtnsizeColor);
}

.btnColor {
  background-color: var(--btnColor);
}
</style>