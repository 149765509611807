<template>
  <div class="input_wrap_wrap flex" :style="{ height: height + 'rpx' }">
    <div
      :style="{ fontSize: `${palceholderSize}rpx`, marginRight: `${gap}rpx` }"
    >
      {{ title || "" }}
      <slot name="title"></slot>
    </div>
    <div class="input_wrap">
      <input
        :type="type"
        :value="value"
        class="left"
        @input="setValue"
        @change="$emit('change', $event.target.value)"
        :password="password"
        :disabled="disabled"
        :disabledColor="disabledColor"
        :placeholderStyle="palceholderStyle"
        :placeholder="placeholder"
      />
      <!-- <div class="right"> -->
      <slot name="right"></slot>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "setValue",
  },
  props: {
    title: {
      type: String,
    },
    disabledColor: {
      type: String,
      default: "#f0ebef",
    },
    gap: {
      type: Number,
      default: 30,
    },
    placeholder: {
      type: String,
      default: "",
    },
    password: {
      type: Boolean,
    },
    type: {
      type: String,
      default: "",
    },
    height: {
      type: Number,
      default: 80,
    },
    palceholderSize: {
      type: Number,
      default: 24,
    },
    disabled: {
      type: Boolean,
    },
    value: "",
  },
  components: {},
  data() {
    this.palceholderStyle = "font-size: calc(24rem / 32); color: #797f8d";
    return {};
  },
  created() {
    this.palceholderStyle = `font-size: ${this.palceholderSize}rpx; color: #797f8d`;
  },
  methods: {
    setValue(e) {
      this.$emit("setValue", e.target.value);
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../common.css");
.input_wrap_wrap {
  height: calc(80rem / 32);
  background-color: var(--bg);
  padding: 0 calc(10rem / 32);
  display: flex;
  // justify-content: center;
  align-items: center;
  border-radius: calc(10rem / 32);
  .input_wrap {
    display: flex;
    height: calc(41rem / 16);
    // background: rgb(240, 235, 239);
    background: var(--nav-background);
    color: var(--theme);
    border-radius: 5px;
    align-items: center;
    padding: 0 calc(16rem / 16);
    justify-content: space-between;
    flex-grow: 1;
    .left {
      background: transparent;
      border: none;
      width: 100%;
    }
    .right {
      display: flex;
      align-items: center;
    }
    input::placeholder{
      color: var(--theme);
    }
  }

}
</style>